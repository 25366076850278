<template>
  <div class="addanswer">
    <!-- <el-button href="#answers" @click="$router.push('answers')">查看答题情况</el-button> -->
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-container>
        <el-main>
          <div class="bigDiv">
            <el-form label-width="80px" :inline="true">
              <el-form-item label="考试批次:">
                <el-select v-model="sBatch" placeholder="考试批次" filterable>
                  <el-option v-for="item in sBatchs" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学号:">
                <el-input v-model="username" placeholder="请输入学号" clearable></el-input>
              </el-form-item>
              <el-form-item label="姓名:">
                <el-input v-model="realname" placeholder="请输入姓名" clearable></el-input>
              </el-form-item>
              <el-form-item label="状态:">
                <el-select v-model="answerStatus" placeholder="请选择" filterable>
                  <el-option v-for="item in cstatus" :key="item.id" :label="item.status"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="平台:">
                <el-select v-model="platform" placeholder="请选择" clearable filterable>
                  <el-option v-for="item in platformlist" :key="item.id" :label="item.info"
                    :value="item.info">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="科目名称:">
                <el-select v-model="cid" placeholder="请选择科目名称" clearable filterable>
                  <el-option v-for="item in cname" :key="item.cid" :label="item.ccourse"
                    :value="item.cid">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务名称:">
                <el-select v-model="sc_task_id" placeholder="请选择任务名称" clearable filterable>
                  <el-option v-for="item in showTasks" :key="item.ttId" :label="item.ttTaskName"
                    :value="item.ttId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button type="primary" @click="goSearch">查询</el-button>
              <el-button type="primary" @click="goSearchAll">清空条件</el-button>
              <el-button type="primary" @click="addresetstatus">重新答题</el-button>
            </el-form>
            <el-table :data="lists" style="width: 100%" :stripe="true" :border="true"
              :height="tableHeight" :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="s_batch" label="批次"></el-table-column>
              <el-table-column prop="s_username" label="学号"></el-table-column>
              <el-table-column prop="s_realname" label="姓名">
              </el-table-column>
              <el-table-column prop="c_course" label="科目名称">
              </el-table-column>
              <el-table-column prop="tt_task_name" label="任务名称">
              </el-table-column>
              <el-table-column prop="s_platform" label="平台">
              </el-table-column>
              <el-table-column prop="st_status" label="答题状态">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.st_status === 2?'success':'warning'">
                    {{scope.row.st_status === 0?'待考试':(scope.row.st_status === 1?'考试中':(scope.row.st_status === 2?'已完成':'考试异常'))}}
                  </el-tag>
                </template>
              </el-table-column>
              <div slot="empty">
                <p>
                  <img :src="imgUrl" />
                </p>
              </div>
            </el-table>
            <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
              @size-change="handleSizeChange" :total="allLength || 0" align="right">
            </el-pagination>
          </div>
        </el-main>
        <!-- 查看详情 -->
        <el-dialog title="重置答题状态" :visible.sync="showmessage" showCancelButton=true
          showConfirmButton=true width="80%">
          <div class="search">
            <el-row :gutter="20">
              <el-col :xs="18" :sm="10" :md="7" :lg="6">
                <el-form label-width="80px">
                  <el-form-item label="批次:">
                    <el-select v-model="modalSBatch" placeholder="批次">
                      <el-option v-for="item in sBatchs" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :xs="18" :sm="10" :md="7" :lg="6">
                <el-form label-width="80px">
                  <el-form-item label="科目名称:">
                    <el-select v-model="cids" placeholder="科目名称" clearable>
                      <el-option v-for="item in cname" :key="item.cid" :label="item.ccourse"
                        :value="item.ccourse">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :xs="18" :sm="10" :md="7" :lg="6">
                <el-form label-width="80px">
                  <el-form-item label="任务名称:">
                    <el-select v-model="sc_tasks" placeholder="任务名称" clearable>
                      <el-option v-for="item in popupTask" :key="item.tt_id"
                        :label="item.tt_task_name" :value="item.tt_task_name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :xs="18" :sm="10" :md="6" :lg="4">
                <el-button type="primary" @click="search">任 务 搜 索</el-button>
              </el-col>
              <!-- 批量搜索 -->
              <el-col :xs="18" :sm="12" :md="7" :lg="13">
                <el-form label-width="80px">
                  <el-form-item label="学号">
                    <el-input v-model="inputText" placeholder="请输入学生学号" clearable></el-input>
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :xs="18" :sm="10" :md="6" :lg="2">
                <el-button type="primary" @click="searchList">学号批量搜索</el-button>
              </el-col>
            </el-row>
          </div>
          <!-- table -->
          <el-table :data="tableData" stripe style="width: 100%; overflow-y: scroll;">
            <el-table-column prop="susername" label="学号">
            </el-table-column>
            <el-table-column prop="srealname" label="姓名">
            </el-table-column>
            <el-table-column prop="spassword" label="密码">
            </el-table-column>
            <el-table-column prop="splatform" label="平台">
            </el-table-column>
            <el-table-column prop="stStatus" label="状态">
              <template slot-scope="scope">
                <el-tag type="danger">
                  {{scope.row.stStatus === 0?'待考试':(scope.row.stStatus === 1?'考试中':(scope.row.stStatus === 2?'已完成':'考试异常'))}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">
                  移除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="resetstatus">重 置 状 态</el-button>
          </span>
        </el-dialog>
        <!-- 更改状态弹出提示框 -->
        <el-dialog title="提示" :visible.sync="dialogFormVisible">
          <el-alert :title=warningText center type="warning" :closable="false">
          </el-alert>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogFormVisibleOK">确 定</el-button>
          </div>
        </el-dialog>
      </el-container>

    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
import commonApi from '@/api/commonApi.js'

export default {
  data () {
    // name: 'Home'
    return {
      // 更改学生状态是科目还是学号批量
      btnUpdateStatus: 0,
      tableHeight: window.innerHeight - 300,
      realname: '', // 姓名
      username: '', // 学号
      cname: [], // 科目名称
      popupCourse: [], // 存放弹窗科目的数组
      popupTask: [], // 存放弹窗任务的数组
      cstatus: [
        { id: 0, status: '待考试' },
        { id: 1, status: '答题中' },
        { id: 2, status: '已完成' },
        { id: -1, status: '异常状态' }
      ], // 状态
      platform: '', // 选中的平台
      platformlist: [
        { id: 0, info: '国家开放大学' },
        { id: 1, info: '随学随考' },
        { id: 2, info: '辅助平台' }
      ], // 存放平台的状态
      cid: '', // 选中的科目id
      cids: '', // 弹窗选择的科目
      tname: [], // 根据科目名称查询任务
      sc_task_id: '', // 选中的任务id
      sc_tasks: '', // 选中的弹窗任务名称
      fileList: [], // 存放文件的数组
      showmessage: false, // 查看弹窗
      dialogFormVisible: false, // 提示警告窗开关
      warningText: '', // 警告框的提示
      inputList: [], // 存放输入更改学生状态的数组
      arrlistTask: [], // 存放任务tt_id的数组 方便更改状态时匹配
      inputText: '', // 存放输入或者是粘贴的学生学号
      tableData: [
        // { idcard: '15242', name: '小胡', status: '待考试' },
        // { idcard: '15242', name: '马大哈', status: '待考试' },
        // { idcard: '15242', name: '马冬梅', status: '待考试' },
        // { idcard: '15242', name: '信春哥', status: '已完成' },
        // { idcard: '15242', name: '小胡', status: '待考试' },
        // { idcard: '15242', name: '马大哈', status: '待考试' },
        // { idcard: '15242', name: '马冬梅', status: '待考试' },
        // { idcard: '15242', name: '信春哥', status: '已完成' },
        // { idcard: '15242', name: '小胡', status: '待考试' },
        // { idcard: '15242', name: '马大哈', status: '待考试' },
        // { idcard: '15242', name: '马冬梅', status: '待考试' },
        // { idcard: '15242', name: '信春哥', status: '已完成' },
        // { idcard: '15242', name: '小胡', status: '待考试' },
        // { idcard: '456', name: '马大哈', status: '待考试' },
        // { idcard: '789', name: '马冬梅', status: '待考试' },
        // { idcard: '15242', name: '信春哥', status: '已完成' },
        // { idcard: '15242', name: '小胡', status: '待考试' },
        // { idcard: '525', name: '马大哈', status: '待考试' },
        // { idcard: '145', name: '马冬梅', status: '待考试' },
        // { idcard: '123', name: '信春哥', status: '已完成' }
      ], // 存放搜索到的数据
      labelPosition: 'right',
      formLabelAlign: {
        sc_realname: '',
        sc_username: '',
        sc_answer_status: '',
        c_name: ''
      },
      dstatus: [{
        name: '单选题',
        answerStatus: 0
      }, {
        name: '多选题',
        answerStatus: 1
      }, {
        name: '填空题',
        answerStatus: 2
      }, {
        name: '附件题',
        answerStatus: -1
      }], // 答题状态
      answerStatus: 0, // 选中的答题状态
      lists: [
        // { s_realname: '20180301', s_username: '张三', c_course: '土木工程', tt_task_name: '工程测量', s_platform: '国考', st_status: '待考试' },
        // { s_realname: '20180301', s_username: '张三', c_course: '土木工程', tt_task_name: '工程测量', s_platform: '国考', st_status: '待考试' },
        // { s_realname: '20180301', s_username: '张三', c_course: '土木工程', tt_task_name: '工程测量', s_platform: '国考', st_status: '待考试' },
        // { s_realname: '20180301', s_username: '张三', c_course: '土木工程', tt_task_name: '工程测量', s_platform: '国考', st_status: '待考试' }
      ], // 获取到的数据
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png'), // 图片地址
      // 当前选中的批次
      sBatch: '',
      // 所有批次列表
      sBatchs: [],
      // 重置状态时选中的批次
      modalSBatch: ''
    }
  },
  computed: {
    showTasks () {
      return this.arrlistTask.filter(item => item.ttCourseId === this.cid)
    }
  },
  mounted () {
    this.allCourseName()
    this.getAllBatchs()
  },
  methods: {
    // 获取所有批次
    async getAllBatchs () {
      const res = await commonApi.getAllBatchs()
      this.sBatchs = (res || []).reverse()
      this.sBatch = res.length > 0 ? res[0] : ''
      this.modalSBatch = res.length > 0 ? res[0] : ''
      this.goSearch()
    },
    // 查看信息
    handleShow: function (index, rows) {
      this.showmessage = true
      this.formLabelAlign = rows
    },
    // 批量搜索
    searchList: function () {
      // 清空数组
      this.tableData = []
      // 重置的状态区分
      this.btnUpdateStatus = 1
      // 点击搜索
      const data = {
        userNames: this.inputText
      }
      for (let i = 0; i < this.popupTask.length; i++) {
        if (this.sc_tasks === this.popupTask[i].tt_task_name) {
          data.st_tt_id = this.popupTask[i].tt_id
        }
      }
      commonApi.showQueryListStudent(data).then((response) => {
        this.tableData = response
      }).catch((response) => {
        console.log(response)
      })
    },
    // 搜索
    search: function () {
      // 清空数组
      this.tableData = []
      // 重置的状态区分
      this.btnUpdateStatus = 0
      // 避免上次未清空的数据
      this.tableData = []
      this.inputText = ''
      // 点击搜索
      const data = {
        student_batch: this.modalSBatch
      }
      for (let i = 0; i < this.popupTask.length; i++) {
        if (this.sc_tasks === this.popupTask[i].tt_task_name) {
          data.st_tt_id = this.popupTask[i].tt_id
        }
      }
      commonApi.showQueryStudent(data).then((response) => {
        this.tableData = response
      }).catch((response) => {
        console.log(response)
      })
    },
    // 点击重置状态打开弹窗
    addresetstatus: function () {
      this.showmessage = true
      // 避免上次未清空的数据
      this.tableData = []
      this.inputText = ''
    },
    // 数组去重的公共方法
    unique: function (params) {
      return Array.from(new Set(params))
    },
    // 提交重 置 状 态 把状态改为待考试
    resetstatus: function () {
      if (this.cids === '' && this.sc_tasks === '') {
        this.warningText = '列表中的学生所有科目答题状态都会被重置为“待考试”'
      } else if (this.cids !== '' && this.sc_tasks === '') {
        this.warningText = '列表中的学生《' + this.cids + '》科目下的所有任务状态都会被重置为“待考试”'
      } else {
        this.warningText = '列表中的学生《' + this.cids + '》科目下的《' + this.sc_tasks + '》任务状态会被重置为“待考试”'
      }
      this.dialogFormVisible = true
    },
    // 确定更新状态
    dialogFormVisibleOK: function () {
      if (this.btnUpdateStatus === 0) {
        const data = {
          // student_batch: this.modalSBatch
        }
        for (let i = 0; i < this.popupTask.length; i++) {
          if (this.sc_tasks === this.popupTask[i].tt_task_name) {
            data.st_tt_id = this.popupTask[i].tt_id
          }
        }
        commonApi.updateStatus(data).then((response) => {
          this.$message({
            message: '更改状态成功',
            type: 'success'
          })
        }).catch((response) => {
          console.log(response)
        })
      } else if (this.btnUpdateStatus === 1) {
        const data = {
          st_id: []
        }
        for (let i = 0; i < this.tableData.length; i++) {
          data.st_id[i] = this.tableData[i].stId
        }
        commonApi.updateOrStuIdStatus(data).then((response) => {
          this.$message({
            message: '更改状态成功',
            type: 'success'
          })
        }).catch((response) => {
          console.log(response)
        })
      }
      this.showmessage = false
      this.dialogFormVisible = false
      this.goSearch()
    },
    // 删除
    handleDelete: function (index, rows) {
      this.tableData.splice(index, 1)
    },
    // 导入题库exl表
    handleChange (file, fileList) {
      this.fileList = fileList.slice(-3)
    },
    // 侧边栏展开
    handleOpen: function (key, keyPath) {
    },
    // 侧边栏关闭
    handleClose: function (key, keyPath) {
    },
    // 查询科目名称
    allCourseName: function () {
      commonApi.selectCourse().then((response) => {
        this.cname = response
        this.popupCourse = this.cname
        this.allTaskName()
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询任务名称获取任务名称的tt_id
    allTaskName: function () {
      const vm = this
      commonApi.queryTestTask().then((response) => {
        vm.arrlistTask = response
      })
    },
    // 查看全部
    goSearchAll: function () {
      this.realname = ''
      this.username = ''
      this.answerStatus = ''
      this.platform = ''
      this.cid = ''
      this.sc_task = ''
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      commonApi.pageQueryStudent(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.length)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询数据
    goSearch: function () {
      const data = {
        batch: this.sBatch,
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      // 学号查找
      if (this.realname) data.realName = this.realname
      // 姓名查找
      if (this.username) data.userName = this.username
      // 状态查找
      if (this.answerStatus !== '') data.status = this.answerStatus
      // 平台查找
      if (this.platform) data.platform = this.platform
      // 科目查找
      if (this.cid !== '') data.courseId = this.cid
      // 任务查找
      if (this.sc_task_id !== '') data.ttId = this.sc_task_id || ''
      commonApi.pageQueryStudent(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  watch: {
    // 监听科目名称下拉选框切换时发送请求获取到任务名称,并且触发查询操作
    cid (val, oldVal) {
      // var cindex = null
      // for (let i = 0; i < this.cname.length; i++) {
      //   if (this.cname[i].ccourse === val) {
      //     cindex = this.cname[i].cid
      //   }
      // }
      this.sc_task_id = ''
      // const data = {
      //   current: this.indexPage, // 当前页
      //   size: this.pageSize, // 每页数据条数
      //   courseId: cindex
      // }
      // commonApi.showTask(data).then((response) => {
      //   this.tname = response.records || []
      // }).catch((response) => {
      //   console.log(response)
      // })
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },
    cids (val, oldVal) {
      var cindex = null
      for (let i = 0; i < this.cname.length; i++) {
        if (this.cname[i].ccourse === val) {
          cindex = this.cname[i].cid
        }
      }
      this.sc_tasks = ''
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize, // 每页数据条数
        courseId: cindex
      }
      commonApi.showTask(data).then((response) => {
        this.popupTask = response.records || []
      }).catch((response) => {
        console.log(response)
      })
    },
    // 监听平台选择做出查询
    platform (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      this.goSearch()
    },
    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },
    // 监听任务名称改变时触发查询操作
    sc_task_id (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },
    // 监听搜索条件的批次修改
    sBatch (val, oldVal) {
      this.modalSBatch = val
    }
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.search {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
